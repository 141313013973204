exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-journalistikk-index-jsx": () => import("./../../../src/pages/journalistikk/index.jsx" /* webpackChunkName: "component---src-pages-journalistikk-index-jsx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-404-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/404.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-404-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-ansetter-elever-for-a-takle-barnehagekrisen-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/ansetter-elever-for-a-takle-barnehagekrisen/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-ansetter-elever-for-a-takle-barnehagekrisen-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-avsluttet-braksommeren-i-hjembyen-var-pa-ekte-litt-stressa-for-konserten-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/avsluttet-braksommeren-i-hjembyen-var-pa-ekte-litt-stressa-før-konserten/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-avsluttet-braksommeren-i-hjembyen-var-pa-ekte-litt-stressa-for-konserten-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-de-fant-3000-vatservietter-langs-havet-pa-en-time-na-jakter-de-den-skyldige-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/de-fant-3000-vatservietter-langs-havet-pa-en-time-na-jakter-de-den-skyldige/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-de-fant-3000-vatservietter-langs-havet-pa-en-time-na-jakter-de-den-skyldige-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-de-unge-og-foreldrene-deres-er-pa-jakt-etter-en-diagnose-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/de-unge-og-foreldrene-deres-er-pa-jakt-etter-en-diagnose/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-de-unge-og-foreldrene-deres-er-pa-jakt-etter-en-diagnose-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-den-evige-dugnaden-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/den-evige-dugnaden/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-den-evige-dugnaden-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-det-er-blitt-trendy-a-redde-biene-det-er-darlig-nytt-for-miljoet-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/det-er-blitt-trendy-a-redde-biene-det-er-darlig-nytt-for-miljoet/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-det-er-blitt-trendy-a-redde-biene-det-er-darlig-nytt-for-miljoet-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-einar-skjonner-ikke-hvorfor-staten-vil-ta-fra-ham-jobben-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/einar-skjonner-ikke-hvorfor-staten-vil-ta-fra-ham-jobben/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-einar-skjonner-ikke-hvorfor-staten-vil-ta-fra-ham-jobben-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-fikk-nei-til-a-amme-ved-bassengkanten-i-norge-kan-kvinner-amme-overalt-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/fikk-nei-til-a-amme-ved-bassengkanten-i-norge-kan-kvinner-amme-overalt/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-fikk-nei-til-a-amme-ved-bassengkanten-i-norge-kan-kvinner-amme-overalt-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-for-gikk-arbeiderne-til-ap-na-gar-de-til-frp-hvorfor-det-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/for-gikk-arbeiderne-til-ap-na-gar-de-til-frp-hvorfor-det/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-for-gikk-arbeiderne-til-ap-na-gar-de-til-frp-hvorfor-det-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-her-trener-de-for-krig-og-eksplosjoner-alvoret-er-et-helt-annet-na-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/her-trener-de-for-krig-og-eksplosjoner-alvoret-er-et-helt-annet-na/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-her-trener-de-for-krig-og-eksplosjoner-alvoret-er-et-helt-annet-na-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-hun-ma-sette-andres-liv-foran-juletid-med-barna-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/hun-ma-sette-andres-liv-foran-juletid-med-barna/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-hun-ma-sette-andres-liv-foran-juletid-med-barna-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-madlaleirens-nye-sjef-militaerlegen-ville-dimittere-meg-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/madlaleirens-nye-sjef-militaerlegen-ville-dimittere-meg/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-madlaleirens-nye-sjef-militaerlegen-ville-dimittere-meg-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-motvind-i-hverdagen-index-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/journalistikk/motvind-i-hverdagen/index.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-journalistikk-motvind-i-hverdagen-index-mdx" */),
  "component---src-templates-article-template-index-jsx-content-file-path-content-om-meg-mdx": () => import("./../../../src/templates/ArticleTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/om-meg.mdx" /* webpackChunkName: "component---src-templates-article-template-index-jsx-content-file-path-content-om-meg-mdx" */),
  "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-bjorn-og-pitter-mdx": () => import("./../../../src/templates/PhotographyTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/foto/bjorn-og-pitter.mdx" /* webpackChunkName: "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-bjorn-og-pitter-mdx" */),
  "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-index-mdx": () => import("./../../../src/templates/PhotographyTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/foto/index.mdx" /* webpackChunkName: "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-index-mdx" */),
  "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-janski-mdx": () => import("./../../../src/templates/PhotographyTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/foto/janski.mdx" /* webpackChunkName: "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-janski-mdx" */),
  "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-litlabruket-mdx": () => import("./../../../src/templates/PhotographyTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/foto/litlabruket.mdx" /* webpackChunkName: "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-litlabruket-mdx" */),
  "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-mona-og-elisabeth-mdx": () => import("./../../../src/templates/PhotographyTemplate/index.jsx?__contentFilePath=/opt/build/repo/content/foto/mona-og-elisabeth.mdx" /* webpackChunkName: "component---src-templates-photography-template-index-jsx-content-file-path-content-foto-mona-og-elisabeth-mdx" */)
}

